import React from "react";

const Row = props => {
  const { theme, children, anchor } = props;

  return (
    <div className={`row-root ${theme}`} id={anchor}>
      <div className="layer-container">
        {children}
      </div>
    </div>
  )
};

export default Row;