import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import Typography from '@material-ui/core/Typography';
import {graphql, Link, useStaticQuery} from "gatsby";
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/styles';
import {clearTheme} from "./theme";
import "./all.scss"
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Helmet from "react-helmet";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import DockIcon from "@material-ui/icons/Dock";
import StoreIcon from '@material-ui/icons/Store';
import HotelIcon from '@material-ui/icons/Hotel';
import AddIcon from '@material-ui/icons/Add';
import PaymentIcon from '@material-ui/icons/Payment';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import Img from "gatsby-image";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    marginRight: theme.spacing(2),
  },
  // appBar: {
  //   marginLeft: drawerWidth,
  //   [theme.breakpoints.up('sm')]: {
  //     width: `calc(100% - ${drawerWidth}px)`,
  //   },
  // },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuTitle: {
    marginLeft: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const LayoutAlt = props => {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery2 {
          site {
              siteMetadata {
                  title
              }
          }
          file(relativePath: {eq: "clear-industries-logo.png"}) {
              childImageSharp {
                  fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `);

  const {container, children} = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <List>
      <div className={classes.toolbar}/>
      <Divider/>
      <Link to="about/#services">
      <ListItem button>
        <ListItemText primary="Services"/>
      </ListItem>
      </Link>
      <Divider/>
      <Link to="about/#about">
      <ListItem button>
        <ListItemText primary="About"/>
      </ListItem>
      </Link>
      <Divider/>
      <Link to="about/#contact">
      <ListItem button>
        <ListItemText primary="Contact"/>
      </ListItem>
      </Link>
      <Divider/>
      <br/>
      <Typography variant="overline" component="p" className={classes.menuTitle}>
        Products
      </Typography>
      <Link to="/index?tab=podiums" >
          <ListItem button>
            <ListItemIcon> <DockIcon/></ListItemIcon>
            <ListItemText primary="Podiums & Pulpits"/>
          </ListItem>
      </Link>
      <Link to="/products-retail?tab=retail" >
          <ListItem button>
            <ListItemIcon> <StoreIcon/></ListItemIcon>
            <ListItemText primary="Retail"/>
          </ListItem>
      </Link>
      <Link to="/products-hospitality?tab=hos">
          <ListItem button>
            <ListItemIcon> <HotelIcon/></ListItemIcon>
            <ListItemText primary="Hospitality"/>
          </ListItem>
      </Link>
      <Link to="/products-ad?tab=ad" >
          <ListItem button>
            <ListItemIcon> <AddIcon/></ListItemIcon>
            <ListItemText primary="Ad Specialties"/>
          </ListItem>
      </Link>
      <Link to="/products-point-of-purchase?tab=pop">
          <ListItem button>
            <ListItemIcon> <PaymentIcon/></ListItemIcon>
            <ListItemText primary="Point of Purchase"/>
          </ListItem>
      </Link>
      <Link to="/products-restaurant?tab=rest" >
          <ListItem button>
            <ListItemIcon> <RestaurantIcon/></ListItemIcon>
            <ListItemText primary="Restaurant"/>
          </ListItem>
      </Link>
    </List>
  );

  return (
    <ThemeProvider theme={clearTheme}>
      <Helmet>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
      </Helmet>
      <div className={classes.root}>
        <CssBaseline/>
        <AppBar position="fixed" color={"secondary"} className={`${classes.appBar} bar-theme`}>
          <Toolbar>
            <Typography variant="h6" style={{maxWidth: "100%"}} className={classes.title}>
            <Link to="/">
              {/*<div style={{maxWidth: "200px"}}>*/}
              {/*   <Img fluid={data.file.childImageSharp.fluid} />*/}
              {/*</div>*/}
              Clear Industries
            </Link>
            </Typography>
            <IconButton
              color="black"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon/>
            </IconButton>
            <Link to="/?tab=podiums"><Button color="inherit" className={classes.button}>Products</Button></Link>
            <Link to="about/#services"><Button color="inherit" className={classes.button}>Services</Button></Link>
            <Link to="about/#about"><Button color="inherit" className={classes.button}>About</Button></Link>
            <Link to="about/#contact"><Button color="inherit" className={classes.button}>Contact</Button></Link>
          </Toolbar>
        </AppBar>
        <nav className={`${classes.drawer} drawer-theme`} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              className="drawer-theme"
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
              {/*<footer>*/}
              {/*  © {new Date().getFullYear()}, Built with*/}
              {/*  {` `}*/}
              {/*  <a href="https://www.gatsbyjs.org">Gatsby</a>*/}
              {/*</footer>*/}
            </Drawer>
          </Hidden>
        </nav>
        <main>
          <div className={classes.toolbar}/>
          {children}
        </main>
      </div>
    </ThemeProvider>
  )
};

export default LayoutAlt;