import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import LayoutAlt from "../components/layoutAlt";
import Row from "../components/row";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {light} from "@material-ui/core/styles/createPalette";

const useStyles = makeStyles(theme => ({

}));

const IndexPage = () => {
  const classes = useStyles();

  return (
  <LayoutAlt>
    <SEO title="Home"/>
    <Row theme="blue">
      <Typography variant="h3" component="h1">
        Clear Industries
      </Typography>
      <br/>
      <Typography variant="p" component="p">
        manufactures plastic fixtures and custom displays at an excellent value.
      </Typography>
      <br/>
      <Typography variant="h5" component="h2">
        What is value?
      </Typography>
      <Typography variant="p" component="p">
        <ul>
          <li>Excellent pricing,</li>
          <li>Superior workmanship</li>
          <li>Fast turn-around times</li>
          <li>Prototype services</li>
        </ul>
      </Typography>
      <br/>
      <Typography variant="p" component="p">

        Located in Minneapolis, Minnesota.

        If you are in the market for acrylic products, we would like to earn your business. Clear Industries is the
        clear choice for your fixture and custom display needs.

        Click on the images to the right to view a sampling of our extensive product line.
      </Typography>
    </Row>

    <Row theme="dark" anchor="services">
      <Typography variant="h4" component="h2">
        Services
      </Typography>
      <br/>
      <Typography variant="h6" component="h3">
        Custom Fabrication
      </Typography>
      <Typography variant="p" component="p">
        <p>
          Custom plastic fabrication is found at the heart of Clear Industries. With over twenty years of experience in
          the industry, we have provided literally thousands of custom display solutions for a very wide range of
          companies and individuals.
        </p>
        <p>
          As our web site illustrates, our custom fabrication caters to the display needs of retail stores and chains,
          restaurants, hospitality groups, ad specialty companies, religious organizations and many others.
        </p>
      </Typography>
      <br/>
      <Typography variant="h6" component="h3">
        Laser Cutting Technology
      </Typography>
      <Typography variant="p" component="p">
        <p>
          Clear Industries' in-house laser cutting provides our clients with a wide array of design possibilities. Our
          laser cutting systems make easy work of complex designs, ornate lettering, custom awards, precision contours,
          holes, slots and more.
        </p>
      </Typography>
      <br/>
      <Typography variant="h6" component="h3">
        Custom Imprinting
      </Typography>
      <Typography variant="p" component="p">
        <p>
          Screen-printing is yet another custom service we offer. Enhance your product appeal and name recognition with
          one or multiple color screen-printing.
        </p>
      </Typography>
      <br/>
      <Link to="/products-podiums?tab=podiums">
      <Button variant={"contained"} >
        See our Products
      </Button>
      </Link>
    </Row>
    <Row theme="offWhite" anchor="about">
      <Typography variant="h4" component="h2">
        About
      </Typography>
      <Typography variant="p" component="p">
        <p>Clear Industries has been a provider of literature and product displays for over twenty years. We have
          provided literally thousands of display solutions for a very wide range of companies and individuals.</p>

        <p>We have satisfied our many customers with our commitment to great pricing, quality products and our ability
          to provide custom display solutions.</p>

        <p>Some of our satisfied customers include AT&T, American Express, Aveda Corporation, Bank One, Carlson
          Companies, Caterpillar, General Mills, Intervarsity Press, Target Corporation and 3M.</p>

        <p>As you visit our web site, it is our hope that you will become part of our growing family of customers.</p>
      </Typography>
    </Row>
    <Row theme="light" anchor="contact">
      <Typography variant="h4" component="h2">
        Contact
      </Typography>
      <br/>
      <Typography variant="p" component="p">
        Toll-Free 800-383-0458<br/>
        Local 612-521-1172<br/>
        After Hours 612-669-7364<br/>
        You may fax inquiries and orders to us at 612-521-1447.<br/>

        <p>Our mailing address is:</p>
        <p>Clear Industries<br/>
        4011 Washington Ave N<br/>
          Minneapolis, MN 55412</p>
      </Typography>
    </Row>
  </LayoutAlt>
  )
};

export default IndexPage
